// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blastring-js": () => import("./../../../src/pages/blastring.js" /* webpackChunkName: "component---src-pages-blastring-js" */),
  "component---src-pages-byggnadssmide-js": () => import("./../../../src/pages/byggnadssmide.js" /* webpackChunkName: "component---src-pages-byggnadssmide-js" */),
  "component---src-pages-certifikat-beslut-9001-14001-js": () => import("./../../../src/pages/certifikat/beslut_9001_14001.js" /* webpackChunkName: "component---src-pages-certifikat-beslut-9001-14001-js" */),
  "component---src-pages-certifikat-beslut-iso-3834-3-js": () => import("./../../../src/pages/certifikat/beslut_iso_3834_3.js" /* webpackChunkName: "component---src-pages-certifikat-beslut-iso-3834-3-js" */),
  "component---src-pages-certifikat-certifikat-9001-14001-js": () => import("./../../../src/pages/certifikat/certifikat_9001_14001.js" /* webpackChunkName: "component---src-pages-certifikat-certifikat-9001-14001-js" */),
  "component---src-pages-certifikat-certifikat-en-1090-1-js": () => import("./../../../src/pages/certifikat/certifikat_en_1090_1.js" /* webpackChunkName: "component---src-pages-certifikat-certifikat-en-1090-1-js" */),
  "component---src-pages-certifikat-certifikat-iso-3834-3-js": () => import("./../../../src/pages/certifikat/certifikat_iso_3834_3.js" /* webpackChunkName: "component---src-pages-certifikat-certifikat-iso-3834-3-js" */),
  "component---src-pages-certifikat-hardness-testing-js": () => import("./../../../src/pages/certifikat/hardness_testing.js" /* webpackChunkName: "component---src-pages-certifikat-hardness-testing-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-lager-js": () => import("./../../../src/pages/lager.js" /* webpackChunkName: "component---src-pages-lager-js" */),
  "component---src-pages-logistik-js": () => import("./../../../src/pages/logistik.js" /* webpackChunkName: "component---src-pages-logistik-js" */),
  "component---src-pages-malning-js": () => import("./../../../src/pages/malning.js" /* webpackChunkName: "component---src-pages-malning-js" */),
  "component---src-pages-montering-js": () => import("./../../../src/pages/montering.js" /* webpackChunkName: "component---src-pages-montering-js" */),
  "component---src-pages-om-oss-js": () => import("./../../../src/pages/om-oss.js" /* webpackChunkName: "component---src-pages-om-oss-js" */),
  "component---src-pages-pallservice-js": () => import("./../../../src/pages/pallservice.js" /* webpackChunkName: "component---src-pages-pallservice-js" */),
  "component---src-pages-plasma-js": () => import("./../../../src/pages/plasma.js" /* webpackChunkName: "component---src-pages-plasma-js" */),
  "component---src-pages-policy-js": () => import("./../../../src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */),
  "component---src-pages-svetsarbete-js": () => import("./../../../src/pages/svetsarbete.js" /* webpackChunkName: "component---src-pages-svetsarbete-js" */),
  "component---src-pages-tillverkning-js": () => import("./../../../src/pages/tillverkning.js" /* webpackChunkName: "component---src-pages-tillverkning-js" */),
  "component---src-pages-tjanster-js": () => import("./../../../src/pages/tjanster.js" /* webpackChunkName: "component---src-pages-tjanster-js" */),
  "component---src-pages-uthyrning-js": () => import("./../../../src/pages/uthyrning.js" /* webpackChunkName: "component---src-pages-uthyrning-js" */)
}

